.scheduleTitle {
  font-size: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider {
  width: 100%;
  height: 0;
  border: 1px solid;
  margin: 25px 0;
  opacity: 0.3;
}
.subTitle {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.contactInfoWrapper {
  width: 100%;
  .officeImage {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.contactInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.mapWrapper {
  width: 100%;
  height: 400px;
  margin: 20px 0;
}

.subTitle2 {
  font-size: 20px;
  display: flex;
  font-weight: 500;
}
.link{
  margin-left: 20px;
  text-decoration: underline;
}
