.contents {
  display: flex;
  flex-direction: column;
  h1 {
    margin: 10px 0;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .bioWrapper {
    width: 100%;
    .staffTitleWrapper {
      width: 100%;
      overflow-wrap: break-word;
      text-wrap: nowrap;
      h1 {
        font-size: 20px;
      }
    }
    .bioImage {
      padding: 10px;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

}
