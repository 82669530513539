.footer {
  background: black;
  .footerContents {
    display: flex;
    flex-direction: column;
    padding: 10px 0 15px 0;
    color: #DEDEDE;

    .footerTitle {
      font-size: 24px;
      font-weight: bold;
      color: white;
    }
    .footerDescription {
      font-size: 18px;
      color: white;
    }
    .contactInfo {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-top: 10px;
      align-items: center;
      &:first-child {
        margin-top: 15px;
      }
      .contactIcon {
        display: flex;
        width: 20px;
        height: 20px;
      }
      .contactText {
        font-size: 16px;
        color: white;
      }
    }
    .tcLinks {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      .tcLink {
        font-size: 15px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .zocdocButtonWrapper {
      align-items: flex-end;
      justify-content: flex-end;
    }
    .copyRight {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      color: whitesmoke;
    }
  }
}
