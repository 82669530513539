.highlightItem {
  display: flex;
  &:hover {
    height: 205px;
    .iconTreeWrapper {
      overflow: visible;
      top: 0;
      -webkit-transition: top 0.27s linear;
      -moz-transition: top 0.27s linear;
      -o-transition: top 0.27s linear;
      transition: top 0.27s linear;
      height: calc(100% + 20px);
      .iconWrapper {
        position: relative;
        width: 85px;
        height: 85px;
        border-radius: 50px;
        left: -17px;
        top: 0;
        -webkit-transition: all 0.27s linear;
        -moz-transition: all 0.27s linear;
        -o-transition: all 0.27s linear;
        transition: all 0.27s linear;
        .itemIcon {
          width: 40px;
          height: 40px;
          -webkit-transition: all 0.27s linear;
          -moz-transition: all 0.27s linear;
          -o-transition: all 0.27s linear;
          transition: all 0.27s linear;
        }
      }
      .iconStem {
        flex-grow: 1;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
    }
    .highlightContents {
      padding-top: 0px;
      -webkit-transition: all 0.27s linear;
      -moz-transition: all 0.27s linear;
      -o-transition: all 0.27s linear;
      transition: all 0.27s linear;
      .highlightTitle {
        font-size: 26px;
        font-weight: bolder;
        -webkit-transition: all 0.27s linear;
        -moz-transition: all 0.27s linear;
        -o-transition: all 0.27s linear;
        transition: all 0.27s linear;
      }
      .highlightLink {
        pointer-events: auto;
        bottom: 30px;
        opacity: 100;
        -webkit-transition: all 0.27s linear;
        -moz-transition: all 0.27s linear;
        -o-transition: all 0.27s linear;
        transition: all 0.27s linear;
      }
    }
  }
  .iconTreeWrapper {
    width: 50px;
    height: 205px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 20px;
    -webkit-transition: top 0.27s linear;
    -moz-transition: top 0.27s linear;
    -o-transition: top 0.27s linear;
    transition: top 0.27s linear;
    .iconWrapper {
      background-color: white;
      position: relative;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      -webkit-transition: all 0.27s linear;
      -moz-transition: all 0.27s linear;
      -o-transition: all 0.27s linear;
      transition: all 0.27s linear;
      .itemIcon {
        display: flex;
        width: 28px;
        height: 28px;
        -webkit-transition: all 0.27s linear;
        -moz-transition: all 0.27s linear;
        -o-transition: all 0.27s linear;
        transition: all 0.27s linear;
      }
    }
    .iconStem {
      z-index: -10;
      position: relative;
      width: 0;
      flex-grow: 1;
      left: 25px;
      border-left: 2px solid;
      -webkit-transition: all 0.27s linear;
      -moz-transition: all 0.27s linear;
      -o-transition: all 0.27s linear;
      transition: all 0.27s linear;
    }
  }
  .highlightContents {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-top: 20px;
    padding-right: 20px;
    -webkit-transition: all 0.27s linear;
    -moz-transition: all 0.27s linear;
    -o-transition: all 0.27s linear;
    transition: all 0.27s linear;
    position: relative;
    .highlightTitle {
      font-weight: bold;
      font-size: 24px;
      height: 73px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid lightgray;
      -webkit-transition: all 0.27s linear;
      -moz-transition: all 0.27s linear;
      -o-transition: all 0.27s linear;
      transition: all 0.27s linear;
      font-family: "Noto Sans", serif;
    }
    .highlightLink {
      position: absolute;
      bottom: 0;
      border-radius: 0;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: all 0.27s linear;
      -moz-transition: all 0.27s linear;
      -o-transition: all 0.27s linear;
      transition: all 0.27s linear;
    }
  }
}
