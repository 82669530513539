.serviceItemWrapper {
  display: flex;
  flex-direction: column;
  .serviceItem {
    font-size: 22px;
    width: auto;
    .itemText {
      cursor: pointer;
    }
  }
}


.contents {
  display: flex;
  flex-direction: column;
  .contentsImage {
    padding: 20px;
  }
  h1 {
    margin-bottom: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  p {
    font-size: 16px;
    white-space: pre-wrap;
  }
  .divider {
    width: 100%;
    height: 0;
    border: 1px solid;
    margin: 25px 0;
    opacity: 0.3;
  }
}
