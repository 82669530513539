.contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 20px;
    color: rgb(78, 106, 157);
    margin: 0;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
