.highlightsWrapper {
  position: relative;
  width: 100%;
  border-bottom: 2px solid;
  .highlightsArea {
    width: 100%;
    //display: flex;
    //flex-direction: row;
    //justify-content: space-evenly;
    //align-items: flex-end;
    //flex-wrap: nowrap;
    display: block;
    padding-top: 30px;
    overflow-y: clip;
    .highlightItem {
      display: flex;
      &:hover {
        height: 205px;
        .iconTreeWrapper {
          overflow: visible;
          top: 0;
          background-color: white;
          -webkit-transition: top 0.43s linear;
          -moz-transition: top 0.43s linear;
          -o-transition: top 0.43s linear;
          transition: top 0.43s linear;
          .iconWrapper {
            position: relative;
            width: 85px;
            height: 85px;
            border-radius: 50px;
            color: red;
            left: -17px;
            top: 0;
            -webkit-transition: all 0.43s linear;
            -moz-transition: all 0.43s linear;
            -o-transition: all 0.43s linear;
            transition: all 0.43s linear;
            .itemIcon {
              width: 40px;
              height: 40px;
              -webkit-transition: all 0.43s linear;
              -moz-transition: all 0.43s linear;
              -o-transition: all 0.43s linear;
              transition: all 0.43s linear;
            }
          }
          .iconStem {
            z-index: -30;
            flex-grow: 1;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
            transition: all 0.3s linear;
          }
        }
        .highlightContents {
          padding-top: 0px;
          -webkit-transition: all 0.43s linear;
          -moz-transition: all 0.43s linear;
          -o-transition: all 0.43s linear;
          transition: all 0.43s linear;
          .highlightTitle {
            font-size: 26px;
            font-weight: bolder;
            -webkit-transition: all 0.43s linear;
            -moz-transition: all 0.43s linear;
            -o-transition: all 0.43s linear;
            transition: all 0.43s linear;
          }
        }
      }
      .iconTreeWrapper {
        width: 50px;
        height: 205px;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 20px;
        -webkit-transition: top 0.43s linear;
        -moz-transition: top 0.43s linear;
        -o-transition: top 0.43s linear;
        transition: top 0.43s linear;
        .iconWrapper {
          position: relative;
          width: 50px;
          height: 50px;
          border-radius: 25px;
          border: 2px solid;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: 0;
          -webkit-transition: all 0.43s linear;
          -moz-transition: all 0.43s linear;
          -o-transition: all 0.43s linear;
          transition: all 0.43s linear;
          .itemIcon {
            display: flex;
            width: 28px;
            height: 28px;
            -webkit-transition: all 0.43s linear;
            -moz-transition: all 0.43s linear;
            -o-transition: all 0.43s linear;
            transition: all 0.43s linear;
          }
        }
        .iconStem {
          position: relative;
          width: 0;
          flex-grow: 1;
          left: 25px;
          border-left: 2px solid;
          -webkit-transition: all 0.43s linear;
          -moz-transition: all 0.43s linear;
          -o-transition: all 0.43s linear;
          transition: all 0.43s linear;
        }
      }
      .highlightContents {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-left: 30px;
        padding-top: 20px;
        padding-right: 20px;
        -webkit-transition: all 0.43s linear;
        -moz-transition: all 0.43s linear;
        -o-transition: all 0.43s linear;
        transition: all 0.43s linear;
        position: relative;
        .highlightTitle {
          font-weight: bold;
          font-size: 24px;
          border-bottom: 1px solid lightgray;
          -webkit-transition: all 0.43s linear;
          -moz-transition: all 0.43s linear;
          -o-transition: all 0.43s linear;
          transition: all 0.43s linear;
        }
      }
    }
  }
}
