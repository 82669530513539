.desktopTopBarWarpper {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  z-index: 30;
}
.desktopTopBar {
  height: 40px;
  width: 100%;
}

.appBarLogo {
  width: 235px;
  object-fit: contain;
  cursor: pointer;
}
.appBarLogoMobile {
  width: 250px;
  object-fit: contain;
  cursor: pointer;
}
