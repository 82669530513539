.roboto-default {
  font-family: "Roboto", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

* {
  font-family: "Roboto", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.MuiContainer-root {
  h1 {
    //color: hsl(210, 98%, 48%);
    color: rgb(78, 106, 157);
  }
  p {
    font-size: 15px;
  }

  .page-title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 1rem;
    width: 100%;
    margin-top: 1.5rem;
    border-bottom: 1px solid #e0e0e0;
  }
}
