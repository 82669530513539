.carouselWrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  .carouselContainer {
    position: relative;
  }
}
.imageItem {
  display: block;
  height: 471px;
  margin: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
